@import "../../Assets/css/variables";

.settings-wrapper {
  min-height: 100vh;
  h1 {
    font-size: 31px;
    color: $primary-text-color;
  }

  h2 {
    font-size: 18px;
    color: $primary-text-color;

  }

  .card {
    border: 0;
    background-color: #ECF2F4;
  }

  .btn-primary {
    width: 100%;
    // background-color: #00A9DA;
    border: 0;
    // border-radius: 50px;
    padding: 10px 35px;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 16px;
    // box-shadow: 1px 1px 0px 0px rgba(2,0,0,0.2);
    background: transparent linear-gradient(263deg, #00A9DA 0%, #0780A3 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00000029;
    border-radius: 42px;
  }

  .input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control, .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select, .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  label {
    font-family: 'Poppins', serif;
  }

}


