$primary-text-color: #004052;
$primary-text-blue: #00A9DA;

$primary-color-light: #EEF7FA;

$bg-light-gray: #ECF2F4;

.text-primary {
  color: $primary-text-color;
}


[data-bs-theme="dark"] {

  .search-form-wrapper {
    background-color: dimgray !important;
  }

  color: white;

  h1, h2, h3, h4, h5, h6 {
    color: white;
  }

  .card {
    .bg-white {
      background-color: transparent !important;
    }
    background-color: transparent;
  }

  input {
    background-color: dimgray;
    &:focus {
      background-color: dimgray;
    }
  }

  .text-primary {
    color: white !important;
  }

  .fw-bold {
    color: white !important;
  }

  .bg-light-gray {
    background-color: #282c34;
  }

}