@import '../../Assets/css/variables';

@media screen and (max-width: 768px) {
  .navbar-brand {
    width: 65%;
  }

  .navbar-toggler {
    border: 0px;

    &:focus {
      border: 0px;
    }
  }
}


.bg-gradient-blue {
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(90deg, rgba(7,128,163,1) 0%, rgba(0,169,218,1) 35%, rgba(0,212,255,1) 100%);

}

// bg gradient without padding
.bg-gradient-blue-wp {
  background: linear-gradient(90deg, rgba(7,128,163,1) 0%, rgba(0,169,218,1) 35%, rgba(0,212,255,1) 100%);
}

.btn-light {
  color: $primary-text-color;
  font-weight: bold;
  background-color: white;
}

@media print {
  .hidden-print {
    display: none !important;
  }
}