@import '../../Assets/css/variables';

footer {
  margin-left: 0;
  position: relative;
  bottom: 0;
  left: 0;
  clear: both;
  width: 100%;
  margin-top: 100px;
}

.footer-background {
  background-color: #EEF7FA;
}

.text-primary {
  color: $primary-text-color !important;
  font-weight: 500;
}
