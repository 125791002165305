@import "../../Assets/css/variables";

.container-info span{
  color: $primary-text-color !important;
}

.search-container {
  height: 200px;
  background: url("../../Assets/header-bg.png");
  margin-bottom: 80px;

  .searchterm-field:focus {
    box-shadow: none;
  }

  @media screen and (max-width: 768px) {
    height: 400px;

    input {
      width: 100% !important;
      text-align: center;
      margin-bottom: 10px;
    }

    .flex-row {
      flex-direction: column !important;
    }

    .search-form-wrapper {
      width: 100%;

      .d-flex {
        display: block;
      }
    }
  }

}
.react-datepicker-wrapper {
  margin-right: 10px !important;
  svg {
    height: 20px;
    fill: $primary-text-color;
  }


  input {
    font-weight: bold;
    font-size: 16px;
  }

  input:focus {
    box-shadow: none;
  }

  input::placeholder {
    font-size: 14px;
    padding-left: 5px;
    color: $primary-text-blue;
    font-weight: bold;
  }

}

.bg-light-gray {
  background-color: $bg-light-gray;
}

.list-group-item {
  border: none;
}

.search-btn {
  width: auto;
  // background-color: #00A9DA;
  border: 0;
  // border-radius: 50px;
  padding: 15px 25px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  // box-shadow: 1px 1px 0px 0px rgba(2,0,0,0.2);
  background: transparent linear-gradient(263deg, #00A9DA 0%, #0780A3 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 30px #00000029;
  border-radius: 42px;
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.btn-outline-primary {
  border: 2px solid $primary-text-blue;
  color: $primary-text-color;
  font-weight: bold;
  border-radius: 50px;
  font-size: 14px;
  &:hover {
    border: 2px solid $primary-text-blue;
    color: $primary-text-color;
    // padding: 10px 15px;
    // color: #FFFFFF;
    background: transparent;
    // background: transparent linear-gradient(263deg, #00A9DA 0%, #0780A3 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 30px #00000029;
    border-radius: 50px;
  }
}

.rdt_Table {

  .rdt_TableRow {
    font-size: 14px;
  }

  .rdt_ExpanderRow {
    font-size: 14px;
  }

  .rdt_TableHeadRow {
    font-size: 14px;
  }

  .btn-outline-info {
    border-color: $primary-text-blue !important;
    color: $primary-text-color;
    font-weight: bold;
    &:hover {
      background-color: transparent;
    }
  }

}



