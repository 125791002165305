
.login-wrapper {
  width: 100%;
  min-width: 780px;
  max-height: 100vh;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-size: cover;

  a {
    color: white;
    text-decoration: none;

  }


  .login-title {
    font-family: 'Poppins', serif;
    font-weight: bold;
    font-size: 44px;
    padding-bottom: 40px;
  }
  .login {
    width: 70%;
    flex-direction: column;
    border-radius: 20px;
    .header {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .email, .password {
      color: #FFFFFF;
      font-size: 17px;
      font-weight: bold;
      input {
        font-size: 28px;
        width: 100%;
        border: 2px solid #B6BFF6;
        height: 60px;
        padding: 0 15px;
        border-radius: 5px;
      }
    }
    .lost-wrapper {
      margin-top: 25px;
      margin-bottom: 25px;
      .lost {
        text-decoration: none;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 17px;
      }
      .login-btn {
        width: 100%;
        // background-color: #00A9DA;
        border: 0;
        // border-radius: 50px;
        padding: 15px 25px;
        color: #FFFFFF;
        font-weight: bold;
        font-size: 21px;
        // box-shadow: 1px 1px 0px 0px rgba(2,0,0,0.2);
        background: transparent linear-gradient(263deg, #00A9DA 0%, #0780A3 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 30px #00000029;
        border-radius: 42px;
      }
    }
  }
}