@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "Assets/css/variables";
body, html {
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', serif;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4 {
  font-family: 'Poppins', serif;
  font-weight: 500 !important;
}

.expandable-row {
  background-color: $primary-color-light;
}

.cursor-pointer:hover {
  cursor:pointer;
}


@media print {
  .hidden-print {
    display: none !important;
  }

  .rdt_TableHead {
    display: none !important;
  }
}


